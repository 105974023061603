<template>
  <div class="p-mt-1 p-ml-0" v-if="getCalendar">
    <slot name="title">
      <div class="p-d-flex p-jc-between">
        <div><h3 class="p-m-0"><strong>{{ getCalendar.name }}</strong></h3></div>
        <div>
          <Button icon="pi pi-plus" class="p-mr-2 p-button-success p-button-rounded p-button-text"
                  v-tooltip.bottom="_t('label_Add_new_event')"
                  @click="createEmptyEvent(); newEventDialog = true;"
          />
        </div>
      </div>
    </slot>
    <slot name="configurator">
      <div class="p-py-0 p-my-0">
        <TabView>
          <TabPanel
              :disabled="!getCalendar"
              :header="_t('menu.general')"
          >
            <div class="p-grid">
              <div class="p-col-3" style="vertical-align: middle;">
                <p>{{ _t('label_private_media_schedule_name') }}</p>
              </div>
              <div class="p-col-9" style="vertical-align: middle;">
                <p><strong>{{ getCalendar.name ?? '...' }}</strong></p>
              </div>
              <div class="p-col-3" style="vertical-align: middle;">
                <p>{{ _t('label_object_name') }}</p>
              </div>
              <div class="p-col-9" style="vertical-align: middle;">
                <p><strong>{{ participantName ?? '...' }}</strong></p>
              </div>
            </div>
            <hr>
              <div class="sc-wrapper">
                <Card>
                  <template #content>
                    <custom-full-calendar
                        v-model:date="date"
                        :events="events"
                        :view="calendarView"
                        :type="calendarType"
                        :viewTypes="calendarViewTypes"
                        :holidays="getHolidays"
                        :options="calendarOptions"
                        v-if="renderCalendar"
                        @click-event="clickEvent"
                    />
                  </template>
                </Card>
              </div>
          </TabPanel>
          <TabPanel :disabled="!alarmPlans.length" :header="_t('label_assignment')">
            <span
                v-for="alarmPlan in alarmPlans"
                v-bind:key="alarmPlan.id"
            >

              <Button :label="alarmPlan.name"
                      class="p-button-link p-button-text p-button-success"
                      @click="toAlarmPlan(alarmPlan.id)"
              />
              <br/>
            </span>
          </TabPanel>
        </TabView>
      </div>
    </slot>
  </div>
  <Dialog v-model:visible="newEventDialog" :style="{width: '500px'}" :modal="true">
    <template #header>
      <h3>{{ _t('label_Add_new_event') }}</h3>
    </template>

    <span class="p-float-label p-mt-5">
      <InputText id="description" type="text" v-model="getEvent.title" style="width: 100%"/>
      <label for="description">{{ _t('label_object_name') }}</label>
    </span>

    <span class="p-float-label p-mt-5">
      <Dropdown
          v-model="getEvent.deviceType"
          class="p-p-0"
          :options="getDevices"
          optionLabel="message"
          dataKey="message"
          :placeholder="_t('label_Select_device')"
          style="width: 100%"
          :showClear="true"
      />
    </span>

    <span class="p-float-label p-mt-5">
      <InputText id="callingNumber" type="text" v-model="getEvent.callingNumber" style="width: 100%"/>
      <label for="callingNumber">{{ _t('label_call_number') }}</label>
    </span>

    <span class="p-float-label p-mt-5">
      <InputNumber id="acknowledgeTime" type="text" v-model="getEvent.acknowledgeTime" class="p-p-0" style="width: 100%"
                   mode="decimal" :useGrouping="false"/>
      <label for="acknowledgeTime">{{ _t('label_ack_time') }}</label>
    </span>

    <div class="p-grid p-mt-5">
      <div class="p-col-4">
        <span class="p-float-label">
          <Calendar id="startDateSelected" v-model="getEvent.startDateSelected" :showTime="false"
                    dateFormat="dd.mm.yy"/>
          <label for="startDateSelected">{{ _t('label.Start_date') }}</label>
        </span>
      </div>
      <div class="p-col-3">
        <span class="p-float-label">
          <Calendar id="startTime" v-model="getEvent.startTime" :showTime="true" :timeOnly="true" :disabled="allDay"/>
          <label for="startTime">{{ _t('label.Start_time') }}</label>
        </span>
      </div>
      <div class="p-col-3">
        <span class="p-float-label">
          <Calendar id="endTime" v-model="getEvent.endTime" :showTime="true" :timeOnly="true" :disabled="allDay"/>
          <label for="endTime">{{ _t('label.End_time') }}</label>
        </span>
      </div>
      <div class="p-col-2 p-pt-2">
        <div class="p-field-checkbox p-pt-1">
          <Checkbox id="allday" v-model="allDay" :binary="true"/>
          <label for="allday">{{ _t('label_all_day') }}</label>
        </div>
      </div>
    </div>

    <span class="p-float-label p-mt-3">
      <Checkbox id="active" v-model="getEvent.active" :binary="true"/>
      <label for="active" class="p-ml-4">{{ _t('label_active') }}</label>
    </span>

    <span class="p-float-label p-mt-3">
      <Checkbox id="repeatType" v-model="getEvent.recurring" :binary="true"/>
      <label for="repeatType" class="p-ml-4">{{ _t('repeat_type') }}</label>
    </span>

    <div v-if="getEvent.recurring">
      <span class="p-float-label p-mt-3">
        <Dropdown
            v-model="getEvent.repeatType"
            :options="getRepeatTypes"
            class="p-p-0"
            optionValue="value"
            placeholder="Select a type"
            style="width: 100%"
        >
          <template #option="slotProps">
            {{ _t(slotProps.option.value) }}
          </template>
          <template #value="slotProps">
            {{ _t(slotProps.value) }}
          </template>
        </Dropdown>
      </span>

      <span class="p-float-label p-mt-5">
        <InputNumber id="repeatEvery" type="text" v-model="getEvent.repeatEvery" class="p-p-0" style="width: 100%"
                     mode="decimal" :useGrouping="false"/>
        <label for="repeatEvery">{{ _t('label_repeat_every') }} ({{ repeatEveryTitle }})</label>
      </span>
      <span class="p-d-flex p-mt-4" v-if="getEvent.repeatType === repeatTypeEnum.WEEKLY">
        <label class="p-mt-1 p-mr-4">{{ _t('label_repeat_by') }}</label>
        <div class="p-d-inline" v-for="weekDay in weekDayEnum" :key="weekDay">
          <Checkbox :id="weekDay" :name="weekDay" :value="weekDay" v-model="getEvent.markedDays"/>
          <label :for="weekDay" class="p-pl-1 p-pr-3">{{ weekDay.charAt(0).toUpperCase() }}</label>
        </div>
      </span>
      <span class="p-d-flex p-mt-4" v-if="getEvent.repeatType === repeatTypeEnum.MONTHLY">
        <label class="p-mt-1 p-mr-4">{{ _t('label_repeat_by') }}</label>
        <Dropdown
            v-model="getEvent.monthSubType"
            :options="repeatByMonth"
            class="p-p-0"
            optionLabel="label"
            optionValue="value"
            placeholder="Select a type"
            style="width: 100%"
        />
      </span>
      <div class="p-d-flex p-flex-row p-mt-5">
        <div class="p-d-inline-flex p-ai-start p-mt-4 p-pr-4"><label for="ends"
                                                                     class="p-ml-2">{{ _t('label_ends') }}</label></div>
        <div class="p-d-inline-flex p-flex-column p-ai-start p-jc-start p-pr-4">
          <div class="p-d-inline-flex">
            <span class="p-field-radiobutton">
              <RadioButton id="endsAfter" value="AFTER_TIMES_END" v-model="getEvent.endType"/>
              <label for="endsAfter">{{ _t('label_after') }}</label>
            </span>
          </div>
          <div class="p-d-inline-flex">
            <span class="p-field-radiobutton">
              <RadioButton id="endsOn" value="ON_DATE" v-model="getEvent.endType"/>
              <label for="endsOn">{{ _t('label_on') }}</label>
            </span>
          </div>
        </div>
        <div class="p-d-inline-flex p-flex-column p-ai-start p-jc-start p-pr-4" style="width: 100%">
          <span class="p-d-inline-flex p-pb-2" style="margin-top: -5px">
            <InputNumber id="countAfterTimesEnd" type="text" v-model="getEvent.countAfterTimesEnd" class="p-p-0"
                         style="width: 100%" mode="decimal" :useGrouping="false"
                         :disabled="getEvent.endType !== 'AFTER_TIMES_END'"/>
            <label for="countAfterTimesEnd" class="p-pt-2 p-pl-2">{{ _t('label_times') }}</label>
          </span>
          <span class="p-d-inline-flex">
            <Calendar id="endDateSelected" v-model="getEvent.endDateSelected" :showTime="false" dateFormat="dd.mm.yy"
                      :disabled="getEvent.endType !== 'ON_DATE'"/>
          </span>
        </div>
      </div>
    </div>

    <template #footer>
      <Button v-if="getIsEditable" :label="_t('label_Remove')" icon="pi pi-trash" style="float: left"
              class="p-button-text p-button-danger" @click="removeSelectedEvent()"/>
      <Button :label="_t('Cancel')" icon="pi pi-times" class="p-button-text" @click="setEventProcess"/>
      <Button :label="_t('Save')" icon="pi pi-check" autofocus @click="createNewEvent()"/>
    </template>
  </Dialog>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex';
import * as moment from 'moment-timezone';
import CustomFullCalendar from '@/components/CustomFullCalendar';
import {ViewEnum} from '@/components/CustomFullCalendar/enums/view.enum';
import {ViewTypeEnum} from '@/components/CustomFullCalendar/enums/view-type.enum';
import _ from 'lodash';
import {RepeatTypeEnum} from "../../enums/repeat-type.enum";
import {WeekDayEnum} from "../../enums/week-day.enum";
import {getListExtended} from '@/api/list'
import router from '@/router'

export default {
  name: 'calendar-configurator',
  components: {
    'custom-full-calendar': CustomFullCalendar,
  },
  props: {
    orgId: {
      type: [String, Number],
      default: "1",
      required: true,
    },
    participantId: {
      type: [Number, String],
      required: true,
    },
    participantName: {
      type: [Number, String],
      required: true,
    },

  },
  data: () => ({
    checked: false,
    participants: [],
    selectedAlarmPlan: null,
    date: moment(),
    events: [],
    firstRun: true,
    calendarView: ViewEnum.TIMELINE,
    calendarType: ViewTypeEnum.MONTH,
    calendarViewTypes: Object.values(ViewTypeEnum).filter(i => i !== ViewTypeEnum.YEAR).map(i => ({
      name: i.toLowerCase(),
      code: i
    })),
    invalidEvents: [],
    calendarOptions: {
      dow: 1,
    },
    renderCalendar: true,
    newEventDialog: false,
    repeatByMonth: [{
      label: 'Day of month',
      value: 'DAY_OF_THE_MONTH',
    }, {
      label: 'Day of week',
      value: 'DAY_OF_THE_WEEK',
    }],
    allDay: false,
    alarmPlans: []
  }),
  created() {
    this.moment = moment;
    this.moment.updateLocale('en', {
      week: {
        dow: 1,
      }
    });
  },
  methods: {
    ...mapActions({
      'callCalendar': 'individualCalendar/callCalendar',
      'callCreateUpdateEvent': 'individualCalendar/callCreateUpdateEvent',
      'callRemoveEvent': 'individualCalendar/callRemoveEvent',
      'callGetHolidays': 'holidays/callGetHolidays',
      'callGetDevices': 'devices/callGetDevices',
    }),
    ...mapMutations({
      'setCalendar': 'individualCalendar/setCalendar',
      'setEvent': 'individualCalendar/setEvent',
      'selectEvent': 'individualCalendar/selectEvent',
      'setIsEditable': 'individualCalendar/setIsEditable',
      'createEmptyEvent': 'individualCalendar/createEmptyEvent',
    }),
    setEventProcess() {
      this.setEvent(null)
      this.newEventDialog = false
    },
    createNewEvent() {
      this.callCreateUpdateEvent(this.participantId)
          .then(isSuccessful => {
            if (isSuccessful) {
              this.newEventDialog = false;
            }
          })
          .catch(error => {
            let data = error.response && error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
            this.$root.showMessage((data.reason ? ': ' + data.reason : ''), 'error')
          });
    },
    generateEvents: function () {
      if (!this.getEvents) return [];
      let events = [];
      const getEvents = JSON.parse(JSON.stringify(this.getEvents));

      const eventGroups = _.groupBy(getEvents, i => i.sourceEventId);
      for (const eventGroupsKey in eventGroups) {
        let times = [];
        let title = '';
        for (const eventKey in eventGroups[eventGroupsKey]) {
          title = eventGroups[eventGroupsKey][eventKey].title;
          times.push({
            start: moment(eventGroups[eventGroupsKey][eventKey].day, 'YYYY-MM-DD').set({
              hours: moment(eventGroups[eventGroupsKey][eventKey].startTime, 'HH:mm:ss').hours(),
              minutes: moment(eventGroups[eventGroupsKey][eventKey].startTime, 'HH:mm:ss').minutes(),
              seconds: moment(eventGroups[eventGroupsKey][eventKey].startTime, 'HH:mm:ss').seconds(),
            }).format('YYYY-MM-DD HH:mm:ss'),
            end: moment(eventGroups[eventGroupsKey][eventKey].day, 'YYYY-MM-DD').set({
              hours: moment(eventGroups[eventGroupsKey][eventKey].endTime, 'HH:mm:ss').hours(),
              minutes: moment(eventGroups[eventGroupsKey][eventKey].endTime, 'HH:mm:ss').minutes(),
              seconds: moment(eventGroups[eventGroupsKey][eventKey].endTime, 'HH:mm:ss').seconds(),
            }).format('YYYY-MM-DD HH:mm:ss'),
          });
        }
        if (times.length) {
          let event = events.find(i => i.sourceEventId === eventGroupsKey) ?? null;
          if (event) {
            event.times = event.times.concat(times);
          } else {
            event = {
              id: eventGroupsKey,
              title,
              times,
            };
            events.push(event);
          }
        }
      }
      this.events = events;
      if (this.firstRun) {
        // This is a fix, do not delete this block
        this.firstRun = false;
        this.date = moment();
      }
      // this.validateWeeks();
      return events;
    },
    forceRerender() {
      this.renderCalendar = false;
      this.$nextTick(() => {
        this.renderCalendar = true;
      });
    },
    clickEvent(event) {
      const eventId = event.target.getAttribute('data-id');
      if (eventId) {
        this.setIsEditable(true);
        this.selectEvent(eventId);
        this.newEventDialog = true;
      }
    },
    removeSelectedEvent() {
      this.$confirm.require({
        message: this._t('label_confirm_deletion'),
        header: this._t('label_Confirmation'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this._t('label_yes'),
        rejectLabel: this._t('label_no'),
        accept: () => {
          this.callRemoveEvent({participantId: this.participantId, sourceEventId: this.getEvent.sourceEventId})
              .then(isSuccessful => {
                if (isSuccessful) {
                  this.setEvent(null);
                  this.newEventDialog = false;
                }
              })
              .catch(error => {
                let data = error.response && error.response.data ? error.response.data instanceof Object ? error.response.data : JSON.parse(error.response.data) : {}
                this.$root.showMessage((data.reason ?? 'Error deleting event'), 'error')
              });
        },
        reject: () => {
          // nothing to do
        }
      });
    },
    toAlarmPlan(id) {
      router.push(`/alarm-plans/${id}`)
    },
  },
  async mounted() {
    await this.callGetHolidays({orgId: this.orgId, year: moment(this.date).year()});
    this.generateEvents();
    this.callGetDevices();
  },
  computed: {
    ...mapGetters({
      'getCalendar': 'individualCalendar/getCalendar',
      'getEvents': 'individualCalendar/getEvents',
      'getEvent': 'individualCalendar/getEvent',
      'getIsEditable': 'individualCalendar/getIsEditable',
      'getRepeatTypes': 'individualCalendar/getRepeatTypes',
      'getHolidays': 'holidays/getHolidays',
      'getDevices': 'devices/getDevices',
    }),
    repeatEveryTitle: function () {
      if (this.getEvent && this.getEvent.repeatType) {
        switch (this.getEvent.repeatType) {
          case RepeatTypeEnum.DAILY:
            return this._t('label_day');
          case RepeatTypeEnum.WEEKDAYS:
          case RepeatTypeEnum.WEEKENDS:
          case RepeatTypeEnum.WEEKLY:
            return this._t('label_week');
          case RepeatTypeEnum.MONTHLY:
            return this._t('label_month');
          case RepeatTypeEnum.YEARLY:
            return this._t('label_year');
        }
      }
      return '';
    },
    weekDayEnum: () => WeekDayEnum,
    repeatTypeEnum: () => RepeatTypeEnum,
  },
  watch: {
    getEvents: function () {
      this.generateEvents();
    },
    getCalendar: function () {
      this.alarmPlans = []
      getListExtended({"alarmPlanMultiFilter": {"members": [{"id": this.participantId}]}}, 'ALARMPLAN', 0, '', 1000, this.orgId).then((response) => {
        this.alarmPlans = response.data.list
        this.totalRecords = response.data.count
        this.loading = false
      })
    },
    date: function () {
      this.generateEvents();
      this.callGetHolidays({orgId: this.orgId, year: moment(this.date).year()})
    },
    allDay: function () {
      if (this.allDay) {
        this.getEvent.startTime = '00:00';
        this.getEvent.endTime = '23:59';
      }
    }
  },
}
</script>

<style scoped>
:deep(.sc-calendar__header) {
  background-color: #d6ddd87d;
}

:deep(.invalid) {
  background-color: #D32F2F42;
}

:deep(.p-inputmask.p-inputtext.p-component.p-filled) {
  width: 80%;
}
</style>
